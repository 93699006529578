.news-card-container{
    max-width: 25%;
    min-width: 11em;
}

.news-card{
    min-width: 10em;
    min-height: 10em;
    max-width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    .news-text{
        display: flex;
        text-align: center;
        width: 100%;
        background-color: rgba(0,0,0,0.75);
        justify-content: center;
        align-items: flex-end;
        span{
            color: white;
            width: 80%;
            margin-bottom: 10px;
        }
        &:hover{
            background-color: rgba(0,0,0,1);
        }
    }
}
.mobile-news-text{
    display: none;
    min-width: 10em;
    max-width: 100%;
    margin: 10px;
    color: white;
}

@media screen and (max-width: 640px){
    .mobile-news-text{
        display: flex;
    }
    .news-card-container{
        background-color: rgba(0,0,0,0.75);
        margin: 5px;
    }
    .news-card{
        .news-text{
            display: none;
        }
    }
    
}