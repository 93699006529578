@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600;1,700&family=The+Nautigal&display=swap');
.card-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #faf3c0;
    padding-bottom: 20px;
    .news-title{
        width: 100%;
        padding: 30px;
        font-weight: 700;
        font-size: 25px;
        font-family: 'Roboto Mono', monospace;
    }
}
.card{
    min-width: 30%;
    max-width: 40%;
    padding:15px;
    background-color: #f5ea92;
    margin: 10px;
    font-family: 'Roboto Mono', monospace;
    align-self: baseline;
    img{
        max-width: 100%;
    }
    .title{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .description{
        font-size: 20px;
        img{
            max-width: 100%;
        }
        &::first-letter {
            color: black;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 640px){
    .card{
        max-width: 80%;
    }
}

