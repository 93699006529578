.main-news-container{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 30px;
    .news-card{
        margin:10px;
        font-family: 'Roboto Mono', monospace;
    }
    background-color: #faf3c0;
    .main-title{
        width: 100%;
        max-height: 20%;
        padding: 30px;
        font-weight: 700;
        font-size: 62px;
        font-family: 'Libre Barcode 39 Extended Text', cursive;
    }
    .sub-title{
        width: 100%;
        max-height: 20%;
        padding: 30px;
        font-weight: 700;
        font-size: 30px;
        font-family: 'Nunito Sans', sans-serif;
        span{
            font-style: italic;
        }
    }
    .loading{
        width: 100%;
        height: max-content;
        text-align: center;
        font-size: 25px;
        font-family: 'Roboto Mono', monospace;
    }
}